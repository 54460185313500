




























































import { Component, Vue } from 'vue-property-decorator';
import DeleteAccountFullWithdrawalViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account/delete-account-full-withdrawal-view-model';

@Component({ name: 'DeleteAccountFullWithdrawal' })
export default class DeleteAccountFullWithdrawal extends Vue {
  delete_account_withdrawal_view_model = Vue.observable(
    new DeleteAccountFullWithdrawalViewModel(this),
  );

  created() {
    this.delete_account_withdrawal_view_model.initialize();
  }
}
