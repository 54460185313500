import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class DeleteAccountFullWithdrawalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly view: Vue;

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.delete-account.delete-account-full-withdrawal';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_report_not_exits = 'allianz_account_report_by_customer_id_list:[], not exists';

  is_loading = true;

  confirm_withdrawal = false;

  balance = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  constructor(view: Vue) {
    this.view = view;
  }

  get amount_formatted() {
    return currencyFormat(this.balance);
  }

  get is_disabled() {
    return !this.confirm_withdrawal || this.is_loading || this.balance <= 0;
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }

  loadAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.customer_id,
      );
      this.balance = allianz_account_report_list.reduce(
        (total, current) => total + current.final_balance_mxn!, 0,
      );
    } catch (error) {
      if (error.message !== this.error_allianz_account_report_not_exits) {
        this.message_notifier.showErrorNotification(
          this.translate('errors.load_allianz_account_report'),
        );
      }
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    await this.loadAllianzBalance();
  }
}
